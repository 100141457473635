import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { getParsedCookie, deleteAllCookies } from '../../lib/session';
import { setStatusAuth, getUserPermissions } from '../../actions/user';
import Config from '../../config/environments';
import { viewModelToModel } from '../../mapper/user';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      component,
      name,
      path,
      location,
      computedMatch,
      authUser,
      userPermissions,
      setStatusAuth: setStatusAuthFunc,
      getUserPermissions: getUserPermissionsFunc
    } = this.props;

    const rest = {
      name,
      path,
      location,
      computedMatch
    };

    const redirectMap = [
      '/beneficios',
      '/lista-de-acoes',
      '/gestor-de-campanhas',
      '/pix',
      '/bancos',
      '/clientes/extrato',
      '/categorias',
      '/meios-pagamentos',
      '/usuarios/backoffice',
      '/usuarios/portal',
      '/dashboard',
      '/permissoes',
      '/grupos',
      '/lojas',
      '/lojas/upload/invoice',
      '/blocklist/clientes',
      '/blocklist/lojas',
      '/biometria-facial',
      '/trusted',
      '/storeUpload',
      '/cnab'
    ];

    let auth = !authUser.state && authUser.data != null;
    const sessionAuth = getParsedCookie(Config.INTEGRATION.USER_INFO_KEY);
    const [realPath] = rest.location.pathname.split('?');

    if (!auth && sessionAuth) {
      const userInfo = viewModelToModel(sessionAuth);
      setStatusAuthFunc(userInfo);
      getUserPermissionsFunc();
    }

    auth = sessionAuth !== null;

    if (userPermissions.error) {
      auth = false;
      toast.error(
        `Ocorreu um erro nas permissões do usuário.
        Tente mais tarde ou entre em contato com o administrador.`
      );
    }

    if (!auth && !sessionAuth) {
      const redirectUrl = `${Config.BASE_WEB_NEW_URL}/login`;
      deleteAllCookies();
      window.location.replace(redirectUrl);
    }

    if (redirectMap.includes(rest.location.pathname) || redirectMap.includes(realPath)) {
      const redirectUrl = `${Config.BASE_WEB_NEW_URL}${rest.location.pathname}`;
      window.location.replace(redirectUrl);
    }

    return (
      <div>
        <Route {...rest} component={component} />
      </div>
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  computedMatch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  authUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  userPermissions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  setStatusAuth: PropTypes.func.isRequired,
  getUserPermissions: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStatusAuth,
      getUserPermissions
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
  userPermissions: state.userState.userPermissions
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
