import Loadable from 'react-loadable';
import Loading from '../views/utils/loading';
import DefaultLayout from '../containers/defaultLayout';
import UserPermission from '../const/userPermission';

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: DefaultLayout
  },
  {
    path: '/home',
    exact: true,
    name: 'Home',
    component: Loadable({
      loader: () => import('../views/home/home'),
      loading: Loading
    })
  },
  {
    path: '/agenda',
    exact: true,
    name: 'Agenda',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS_SCHEDULE,
    component: Loadable({
      loader: () => import('../views/schedule/schedule'),
      loading: Loading
    })
  },
  {
    path: '/permissoes',
    exact: true,
    name: 'Permissões',
    permission: UserPermission.PERMISSIONS.PERMISSIONS,
    component: Loadable({
      loader: () => import('../views/permission/permissionList'),
      loading: Loading
    })
  },
  {
    path: '/permissoes/form',
    name: 'Formulário',
    permission: 'EAI_BO_PERMISSIONS',
    component: Loadable({
      loader: () => import('../views/permission/permissionForm'),
      loading: Loading
    })
  },

  {
    path: '/grupos/form',
    name: 'Formulário',
    permission: 'EAI_BO_GROUPS',
    component: Loadable({
      loader: () => import('../views/group/groupForm'),
      loading: Loading
    })
  },
  {
    path: '/optin',
    exact: true,
    name: 'Optin',
    permission: UserPermission.PERMISSIONS.OPTIN,
    component: Loadable({
      loader: () => import('../views/optin/optinList'),
      loading: Loading
    })
  },
  {
    path: '/optin/form',
    name: 'Formulário',
    permission: UserPermission.PERMISSIONS.OPTIN,
    component: Loadable({
      loader: () => import('../views/optin/optinForm'),
      loading: Loading
    })
  },
  {
    path: '/repasses/sintetico/repasse-do-dia',
    name: 'Repasse do dia',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS_RESUME,
    component: Loadable({
      loader: () => import('../views/receivable-resume/today-transfer'),
      loading: Loading
    })
  },
  {
    path: '/repasses/sintetico',
    name: 'Sintético',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS_RESUME,
    component: Loadable({
      loader: () => import('../views/receivable-resume/receivableResume'),
      loading: Loading
    })
  },
  {
    path: '/repasses/confirmacao',
    name: 'Confirmação',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/transfer/transferConfirm'),
      loading: Loading
    })
  },
  {
    path: '/repasses',
    exact: true,
    name: 'Repasses',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/receivable/receivableList'),
      loading: Loading
    })
  },
  {
    path: '/repasses/exportar',
    exact: true,
    name: 'Exportar planilha',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/receivable/downloadReceivable'),
      loading: Loading
    })
  },
  {
    path: '/pedidos/detalhes/:uuid/:code',
    exact: true,
    name: 'Detalhes',
    permission: UserPermission.PERMISSIONS.ORDERS,
    component: Loadable({
      loader: () => import('../views/order/orderDetail'),
      loading: Loading
    })
  },
  {
    path: '/repasses/parcelas/detalhes/:uuid/:code',
    exact: true,
    name: 'Detalhes',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/receivable/receivableDetail'),
      loading: Loading
    })
  },
  {
    path: '/repasses/parcelas/:uuid',
    exact: true,
    name: 'Vendas',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/receivable/installmentList'),
      loading: Loading
    })
  },
  {
    path: '/agenda/parcelas/:uuid',
    exact: true,
    name: 'Vendas',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/receivable/installmentList'),
      loading: Loading
    })
  },
  {
    path: '/agenda/parcelas/detalhes/:uuid/:code',
    exact: true,
    name: 'Detalhes',
    permission: UserPermission.PERMISSIONS.STORES_PAYMENTS,
    component: Loadable({
      loader: () => import('../views/receivable/receivableDetail'),
      loading: Loading
    })
  },
  {
    path: '/performance',
    name: 'Relatório de Performance',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/reportPerfomance'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/geral',
    name: 'Cadastro de Loja - Geral',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_CREATE,
    component: Loadable({
      loader: () => import('../views/store/storeFormGeneral'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/endereco',
    name: 'Cadastro de Loja - Endereço',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_CREATE,
    component: Loadable({
      loader: () => import('../views/store/storeFormAddress'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/financeiro',
    name: 'Cadastro de Loja - Financeiro',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_CREATE,
    component: Loadable({
      loader: () => import('../views/store/storeFormFinancial'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/ipiranga',
    name: 'Cadastro de Loja - Dados Ipiranga',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_CREATE,
    component: Loadable({
      loader: () => import('../views/store/storeFormIpiranga'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/enriquecimento',
    name: 'Cadastro de Loja - Enriquecimento',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_CREATE,
    component: Loadable({
      loader: () => import('../views/store/storeFormEnrich'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/resumo',
    name: 'Cadastro de Loja - Resumo',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_CREATE,
    component: Loadable({
      loader: () => import('../views/store/storeFormResume'),
      loading: Loading
    })
  },
  {
    path: '/lojas/cadastro/upload',
    name: 'Cadastro de Loja por Excel - Upload',
    exact: true,
    permission: UserPermission.PERMISSIONS.EAI_BO_STORE_UPLOAD,
    component: Loadable({
      loader: () => import('../views/store/storeUpload'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/geral/:uuid',
    name: 'Edição de Loja - Geral',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/storeFormGeneral'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/endereco/:uuid',
    name: 'Edição de Loja - Endereço',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/storeFormAddress'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/enriquecimento/:uuid',
    name: 'Edição de Loja - Enriquecimento',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/storeFormEnrich'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/financeiro/:uuid',
    name: 'Edição de Loja - Financeiro',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/storeFormFinancial'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/upload',
    name: 'Edição de Loja por Excel - Upload',
    exact: true,
    permission: UserPermission.PERMISSIONS.EAI_BO_STORE_UPLOAD_UPDATE,
    component: Loadable({
      loader: () => import('../views/store/storeUpdate'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/pix/:uuid',
    name: 'Edição de Loja - PIX',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/storeFormPix'),
      loading: Loading
    })
  },
  {
    path: '/lojas/edicao/ipiranga/:uuid',
    name: 'Edição de Loja - Dados Ipiranga',
    exact: true,
    permission: UserPermission.PERMISSIONS.STORES_VIEW,
    component: Loadable({
      loader: () => import('../views/store/storeFormIpiranga'),
      loading: Loading
    })
  },
  {
    path: '/clientes',
    exact: true,
    name: 'Clientes',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/customerList'),
      loading: Loading
    })
  },
  {
    path: '/clientes/form',
    exact: true,
    name: 'Clientes',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/customerForm'),
      loading: Loading
    })
  },
  {
    path: '/clientes/edicao/form/:uuid',
    exact: true,
    name: 'Detalhes do cliente',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/customerForm'),
      loading: Loading
    })
  },
  {
    path: '/clientes/edicao/:document/extrato/:uuid',
    exact: true,
    name: 'Extratos',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/costumerTransactionList'),
      loading: Loading
    })
  },
  {
    path: '/clientes/billet/:transactionId/customer/:uuid',
    exact: true,
    name: 'Detalhes do Boleto',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/billetDetail'),
      loading: Loading
    })
  },
  {
    path: '/clientes/ted/:transactionId/customer/:uuid',
    exact: true,
    name: 'Detalhes do TED',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/transactionDetail'),
      loading: Loading
    })
  },
  {
    path: '/clientes/:view/:transactionId/customer/:uuid',
    exact: true,
    name: 'Detalhes da Transação',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/generalTransactionDetails'),
      loading: Loading
    })
  },
  {
    path: '/clientes/:customerId/pedido/:orderId/form/detalhe',
    exact: true,
    name: 'Detalhes do Pedido',
    permission: UserPermission.PERMISSIONS.CUSTOMERS,
    component: Loadable({
      loader: () => import('../views/customer/customerOrderDetail'),
      loading: Loading
    })
  },
  {
    path: '/trusted',
    exact: true,
    name: 'Trusted',
    permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION_TRUSTED,
    component: Loadable({
      loader: () => import('../views/customer-fraud-prevention/trusted'),
      loading: Loading
    })
  },
  {
    path: '/blocklist-customers',
    exact: true,
    name: 'Blocklist Customers',
    permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION_BLOCKLIST_PF,
    component: Loadable({
      loader: () => import('../views/customer-fraud-prevention/blocklist.customers'),
      loading: Loading
    })
  },
  {
    path: '/blocklist-stores',
    exact: true,
    name: 'Blocklist Stores',
    permission: UserPermission.PERMISSIONS.EAI_FRAUD_PREVENTION_BLOCKLIST_PJ,
    component: Loadable({
      loader: () => import('../views/customer-fraud-prevention/blocklist.stores'),
      loading: Loading
    })
  },
  {
    path: '/facial-biometrics',
    exact: true,
    name: 'Atualização de Biometria Facial',
    permission: UserPermission.PERMISSIONS.EAI_BO_FACIAL_BIOMETRICS,
    component: Loadable({
      loader: () => import('../views/customer-fraud-prevention/facial.biometrics'),
      loading: Loading
    })
  },
  {
    path: '/pedidos',
    exact: true,
    name: 'Pedidos',
    permission: UserPermission.PERMISSIONS.ORDERS,
    component: Loadable({
      loader: () => import('../views/order/orderList'),
      loading: Loading
    })
  },
  {
    path: '/notas',
    exact: true,
    name: 'Notas',
    permission: UserPermission.PERMISSIONS.ORDERS,
    component: Loadable({
      loader: () => import('../views/invoices/invoiceList'),
      loading: Loading
    })
  },
  {
    path: '/vouchers',
    exact: true,
    name: 'Vouchers',
    permission: UserPermission.PERMISSIONS.ORDERS,
    component: Loadable({
      loader: () => import('../views/vouchers/vouchersList'),
      loading: Loading
    })
  },
  {
    path: '/lancamento-contabil',
    exact: true,
    name: 'Lançamento Contábil',
    permission: UserPermission.PERMISSIONS.ORDERS,
    component: Loadable({
      loader: () => import('../views/lancamento-contabil/lancamentoContabilList'),
      loading: Loading
    })
  },
  {
    path: '/relatorios',
    exact: true,
    name: 'Relatórios',
    permission: UserPermission.PERMISSIONS.REPORTS,
    component: Loadable({
      loader: () => import('../views/report/'),
      loading: Loading
    })
  }
];

export default routes;
